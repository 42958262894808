export default defineNuxtRouteMiddleware((to) => {
  const { clients } = useClientStore(usePinia());
  if (clients.length === 0) {
    if (to.path.includes("/onboarding")) {
      return;
    }
    const appUserHelpers = useAppUser();
    const appUserStore = useAppUserStore(usePinia());
    const isAdmin = appUserHelpers.isAdmin(appUserStore.appUserMe);
    if (isAdmin) {
      return navigateTo("/onboarding/create-connection");
    }
    return navigateTo("/onboarding/select-client");
  }
});
